import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import printTemplate from "@/views/tools/sale-card-open/modules/printTemplate";
import vueEasyPrint from "vue-easy-print";
import {
  createCardTypes,
  createPayType,
  createRefundType,
  createTradeType,
  createCardCloseType,
  createCardOpenType,
  createCardCloseStatusType,
  createCardOpenStatusType,
} from "@/views/tools/sale-card-open/utils/type";
import {
  getAppTypeName,
  getRefundTypeName,
  getTradeTypeName,
  getPayTypeName,
  getCardCloseTypeName,
  getCardOpenTypeName,
  getCardCloseStatusTypeName,
  getCardOpenStatusTypeName
} from "@/views/tools/sale-card-open/utils/find";

const mixins = {
  components: {
    EnTableLayout,
    printTemplate,
    vueEasyPrint,
  },
  data() {
    return {
      cardTypes: createCardTypes("all"),
      payType: createPayType("all"),
      refundType: createRefundType("all"),
      tradeType: createTradeType("all"),
      cardCloseType: createCardCloseType("all"),
      cardOpenType: createCardOpenType("all"),
      cardCloseStatusType:createCardCloseStatusType("all"),
      cardOpenStatusType:createCardOpenStatusType("all"),
      params: {
        page_no: 1,
        page_size: 20,
      },
      tableData: {},
      printTableData: {}, // 打印传的对象
      multipleSelection: [],
    };
  },
  methods: {
    getCardCloseStatusTypeName(value) {
      return getCardCloseStatusTypeName(value);
    },
    getCardOpenStatusTypeName(value) {
      return getCardOpenStatusTypeName(value);
    },
    getCardCloseTypeName(value) {
      return getCardCloseTypeName(value);
    },
    getCardOpenTypeName(value) {
      return getCardOpenTypeName(value);
    },
    getAppTypeName(value) {
      return getAppTypeName(value);
    },
    getRefundTypeName(value) {
      return getRefundTypeName(value);
    },
    getTradeTypeName(value) {
      return getTradeTypeName(value);
    },
    getPayTypeName(value) {
      return getPayTypeName(value);
    },
    handlePageSizeChange(size) {
      this.params.page_no = 1;
      this.params.page_size = size;
      this.getList();
      this.$nextTick(() => {
        this.$refs.enTableLayout && this.$refs.enTableLayout.clearSelected();
      });
    },
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handlePrintTable (data, list, type) {
      this.printTableData = data;
      this.printTableData["card_range_list"] = list;
      this.printTableData["type"] = type;
      this.$nextTick(() => {
        this.$refs.easyPrint && this.$refs.easyPrint.print();
      });
    },
    getRowKeys(row) {
      return row.id;
    },
    round(number){
      return Math.round(number * 100) / 100
    }
  },
};

export default mixins;
